import React from 'react'
import MenuSider from './MenuSider'
import {Layout} from 'antd';
import '../styles/sider.css'
const { Sider } = Layout;

const SiderLayout = ({collapsed , rootsubmenukeys}) => {
  return (
        <Sider trigger={null} collapsible collapsed={collapsed} >
          <MenuSider rootSubmenuKeys={rootsubmenukeys}/>
        </Sider>
  )
}

export default SiderLayout