import { React, useState } from 'react'
import { Button, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../styles/headerInner.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
const HeaderInner = (props) => {

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const PostHeaderForm = () => {
    axios.post(window.configurations.apiAddress + props.apiUrl, props.data,
      {
        headers:
        {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + getCookie("admin-AccessToken"),
        }
      })
      .then(response => {
        message.success("Operation Successful");
        if (typeof props.onButtonClick === 'function') {
          props.onButtonClick();
        }
        
      })
      .catch(error => {
        message.error("Operation Failed");
      });
  }
  return (
    <div className='headerInner'>
      <div className="content-header">
        <h2 className='title-header'>{props.title}</h2>
        <div className="btn-header">
          <Button onClick={PostHeaderForm}>{props.btnText}</Button>
          <Link to={`${props.prevPage}`} className='prevPage'>
            <ArrowRightOutlined />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HeaderInner