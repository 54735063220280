import React from "react";
import CkEditor from "./CkEditor/CkEditor";
import { Button, Checkbox, Form, Input } from "antd";

const EditPartsEditor = (props) => {
    return (
        <div>
            <div className="form-partAdd">
                <Form
                    layout="vertical"
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    {props.children}
                    <div className="form-details">
                        <Form.Item
                            label="name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your name!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="condition"
                            name="condition"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your condition!",
                                },
                            ]}
                        >
                            <CkEditor />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default EditPartsEditor;
