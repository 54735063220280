import React from "react";
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import AddUser from "../AddUser";
import ContentLayout from "../Admin";
import HtmlPart from "../Setting/HtmlPart";
import HtmlPartAdd from "../Setting/HtmlPartAdd";
import TermConAdd from "../Setting/TermConAdd";
import TermCondition from "../Setting/TermCondition";
import VerifyUser from "../VerifyUser";
import Support from "../Support/Support";
import AnswerTicket from "../Support/AnswerTicket"

const Routers = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/admin/users" />} />
            <Route path="/admin/users" element={<ContentLayout />} />
            <Route path="/admin/users/add" element={<AddUser />} />
            <Route path="/setting/htmlPart" element={<HtmlPart />} />
            <Route path="/setting/termcondition" element={<TermCondition />} />
            <Route path="/setting/htmlPart/add/1" element={<HtmlPartAdd />} />
            <Route path="/setting/htmlPart/add" element={<HtmlPartAdd />} />
            <Route path="/setting/termcondition/add/1" element={<TermConAdd />} />
            <Route path="/setting/termcondition/add" element={<TermConAdd />} />
            <Route path="/admin/users/kyc/:id" element={<VerifyUser/>} />
            <Route path='/admin/support' element={<Support/>}/>
            <Route path='/admin/support/answerTicket/:id' element={<AnswerTicket />} />
        </Routes>
    );
};

export default Routers;
