import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../styles/addUser.css";
import HeaderInner from "./HeaderInner";

const AddUsers = () => {

    const [user, setUser] = useState([]);

    const onFinish = (values) => {
        console.log("Success:", values);
        setUser(values);
    };
    
    useEffect(() => {
        console.log(user);
    });
    
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const [form] = Form.useForm();

    const { Option } = Select;
    
    return (
        <>
            <div className="add-user">
                <div className="form_add-user">
                    <Form
                        layout="vertical"
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <HeaderInner title={'Add New User'} btnText='Add' prevPage='/' />
                        
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your name!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Family"
                            name="family"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your family!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mobile"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your mobile!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your email!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Repassword"
                            name="repassword"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your repassword!",
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            name="country"
                            label="Country"
                            rules={[{ required: true }]}
                        >
                            <Select allowClear>
                                <Option value="Afghanistan">Afghanistan</Option>
                                <Option value="Aland Islands">
                                    Aland Islands
                                </Option>
                                <Option value="Albania">Albania</Option>
                                <Option value="Algeria">Algeria</Option>
                                <Option value="AmericanSamoa">
                                    AmericanSamoa
                                </Option>
                                <Option value="Andorra">Andorra</Option>
                                <Option value="Angola">Angola</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="Role"
                            label="Role"
                            rules={[{ required: true }]}
                        >
                            <Select allowClear>
                                <Option value="admin">admin</Option>
                                <Option value="supporter">supporter</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        ></Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default AddUsers;
