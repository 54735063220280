import { Button, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../styles/support.css";

const Support = () => {
  const [tickets, setTickets] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const getTickets = (page: number) => {
    axios.post(window.configurations.apiAddress + 'Admin/Ticket/GetTickets',
      {
        page: page,
        limit: 10,
        calledByAdmin: true
      },
      {
        headers:
        {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + getCookie("admin-AccessToken"),
        }
      }).then(({ data }) => {
        var datas = data.items.map((t) => ({
          ticketId: t.ticketId,
          userId: t.userId,
          userName: t.userName,
          ticketType: t.ticketType,
          status: t.status,
          title: t.title,
          createDate: t.createDate
        }));
        setTickets(datas);
        var paging = {
          current: page,
          pageSize: 10,
          total: data.totalSize,
        };
        setPagination(paging);
      }).catch((err) => {
        console.log(err);
      });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setPagination({
      ...pagination,
      current,
      pageSize
    });
    getTickets(current, pageSize);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'ticketId',
      key: 'ticketId',
    },
    {
      title: 'Subject',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Action',
      dataIndex: 'ticketId',
      key: 'action',
      render: (id) => (
        <Link to={`answerTicket/${id}`}>
          <Button>Add Message To Ticket</Button>
        </Link>
      ),
    }
  ];

  useEffect(() => {
    getTickets(pagination.current);
  }, []);

  return (

    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        minHeight: "100vh",
      }}>
      <div className='innerSection'>
        <div className='support-head-title'><h3>Ticket</h3></div>
        <div className='support-table'>
          <Table
            dataSource={tickets}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </Content>
  );
};

export default Support;