import React, { useState } from "react";
import {
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Table,
    Typography,
    Tooltip,
    Modal,
    Button,
} from "antd";
import { EditTwoTone, FullscreenOutlined } from "@ant-design/icons";
import '../../styles/modal.css'
import { htmlPartData } from "../../data";
import "../../styles/htmlPart.css";
import Toolbar from "../Toolbar";
import BreadcrumbCmp from "../‌Breadcrumb";
import { Link } from "react-router-dom";
import CkEditor from "../CkEditor/CkEditor";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const HtmlPart = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState(htmlPartData);
    const [editingKey, setEditingKey] = useState("");
    const [open, setOpen] = useState(false);

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: "",
            createDate: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            key: "0",
            title: "Name",
            dataIndex: "name",
            width: "15%",
            editable: true,
        },
        {
            key: "1",
            title: "Create Date",
            dataIndex: "createDate",
            width: "30%",
            editable: true,
        },
        {
            title: "Actions",
            width: "30%",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <span>
                            <Tooltip title="Edit">
                                <Typography.Link>
                                    <button
                                        style={{ borderColor: "#d5a847" }}
                                        className="ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary"
                                    >
                                        <Link to={"add/1"}>
                                            <EditTwoTone
                                                twoToneColor="#d5a847"
                                                style={{ fontSize: 14 }}
                                            />
                                        </Link>
                                    </button>
                                </Typography.Link>
                            </Tooltip>
                            <Tooltip title="Preview">
                                <Typography.Link
                                    onClick={() => {
                                        setOpen(true);
                                        console.log(open);
                                    }}
                                >
                                    <button className="ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary">
                                        <FullscreenOutlined
                                            twoToneColor="#ff4d4f"
                                            style={{ fontSize: 14 }}
                                        />
                                    </button>
                                </Typography.Link>
                                <Modal
                                    title = 'Preview'
                                    centered
                                    open={open}
                                    bodyStyle={{ color: "#707070" }}
                                    // okButtonProps={{ disabled: true }}
                                    onCancel={() => setOpen(false)}
                                    width={1000}
                                    footer={[
                                        <Button
                                            key="back"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </Button>,
                                    ]}
                                >
                                    <p>{data[0].name}</p>
                                    <p>{data[0].name}</p>
                                    <p>{data[0].name}</p>
                                </Modal>
                            </Tooltip>
                        </span>
                    </>
                );
            },
        },
    ];

    return (
        <div className="html-part">
            <BreadcrumbCmp item1="Admin Panel" item2="Html Part List" />
            <Toolbar btnTxt="Add New" linkTo = 'add'/>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    // pageSize:  ,
                    defaultPageSize: 1,
                    showTitle: false,
                    showLessItems: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
            />
        </div>
    );
};

export default HtmlPart;
