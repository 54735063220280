export const originData = [];

for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: '---' ,
    family: '---',
    mobile : '09120836682',
    email : 'false' ,
    country : 'Iran, Islamic Republic of Persian Gulf' ,
  });
}

export const htmlPartData = [];

for (let i = 0; i < 100; i++) {
  htmlPartData.push({
    key: i.toString(),
    name: 'test' ,
    createDate : '2022-03-03'

  });
}

export const termConditionData = [];
