import React, { useState } from "react";
import { Menu } from "antd";

import { 
  UserOutlined, 
  SettingOutlined ,
  QuestionCircleOutlined
}from "@ant-design/icons";
import { Link } from "react-router-dom";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export const ItemsMenu = [
    getItem(
        <Link to={'/admin/users'}>UserMangement</Link>
        , "sub1", <UserOutlined />),
    getItem("Setting", "sub2", <SettingOutlined />, 
        [getItem(
            <Link to={'/setting/htmlPart'}>KYC Html Part</Link>
            , "3"
            ),
        getItem(
            <Link to={'/setting/termcondition'}>KYC Term Condition</Link>
            , "4"
            ),]
    ),
    getItem(
        <Link to={'/admin/support'}>Support</Link>
        , "sub3", <QuestionCircleOutlined />)
]

const rootSubmenuKeys = ["sub1", "sub2"];

const MenuSider = () => {
    const [openKeys, setOpenKeys] = useState(["sub1"]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    // ***********************************************************
    
    return (
        <>
            <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                items={ItemsMenu}
            />
        </>
    );
};

export default MenuSider;
