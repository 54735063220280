import React from 'react'
import CkEditor from '../CkEditor/CkEditor'
import HeaderInner from '../HeaderInner'
import '../../styles/partAdd.css'
import EditPartsEditor from '../EditPartsEditor'
const TermConAdd = () => {
  return (
    <div className='partAdd'>
        <EditPartsEditor>
            <HeaderInner title = 'Terms And Conditions' btnText = 'Edit' prevPage = '/setting/termcondition'/>
        </EditPartsEditor>
    </div>
  )
}

export default TermConAdd