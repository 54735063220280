import { MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from "antd";
import React from "react";
import logo from '../images/Elitecrescent.svg';
import '../styles/header.css';


const { Header } = Layout;

const handleLogout = () => {
  let cookieValue = `admin-AccessToken = 1`;
  cookieValue = `${cookieValue}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  cookieValue = `${cookieValue}; domain=${window.location.hostname}; path=/;`;
  document.cookie = cookieValue;
  setTimeout(window.location.reload(), 1000);  
  
};

const HeaderLayout = ({ collapsed, setcollapsed }) => {
  return (
    <Row>
      <Col span={24}>
        <Header className="site-layout-background" style={{ backgroundColor: 'whitesmoke' }}>
          <a href={window.configurations.website} className="logo">
            <img src={logo} alt='Elitecrescent-logo' />
          </a>
          <div className="trigger-container">
            <div className="trigger-icon">
              {React.createElement(
                collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setcollapsed(!collapsed),
                }
              )}
            </div>
          </div>
          <div className="logout-container">
            <Button type="text" icon={<PoweroffOutlined />} onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Header>
      </Col>
    </Row>
  );
};

export default HeaderLayout;
