import { Col, Form, List, Row } from "antd";
import axios from "axios";
import VirtualList from 'rc-virtual-list';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import "../styles/verifykyc.css";
import HeaderInner from "./HeaderInner";

const VerifyUser = () => {
    const params = useParams()
    const [user, setUser] = useState([]);
    const [jsonVerify, setJsonVerify] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const getUserInfo = () => {
        axios.get(window.configurations.apiAddress + 'admin/user/GetInformation/' + params.id,
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getCookie("admin-AccessToken"),
                }
            }).then(({ data }) => {
                setUserInfo(data);
                setJsonVerify({"id": data.id, "active": !data.isActive});
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    const onFinish = (values) => {
        console.log("Success:", values);
        setUser(values);
    };

    useEffect(() => {
        console.log(user);
    });

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const [form] = Form.useForm();

    const handleVerifyButtonClick = () => {
        setJsonVerify({"id": jsonVerify.id, "active": !jsonVerify.active})
      };

    return (
        <div className="verifyKyc">
            <Form
                layout="vertical"
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{


                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                {userInfo != null && 
                
                    <HeaderInner
                        onButtonClick = {handleVerifyButtonClick}
                        title="Verify User KYC"
                        btnText={jsonVerify.active ? "Verify": "UnVerify"}
                        prevPage="/"
                        apiUrl= "admin/user/Activation"
                        data = {jsonVerify}
                    />
                }

                <div className="verify-info-container">

                    <p class="sc-title hybeVE">Basic Details</p>
                    {userInfo != null &&
                        <div class="ant-row" style={{ gap: '24px' }} >
                            <div class="sc-table-info">
                                <div class="sc-table-title">Title</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.title}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Full Name</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{(userInfo.name ? userInfo.name : '') + " " + (userInfo.family ? userInfo.family : '')}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Mobile</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.mobile}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Email</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.email}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Is Confirm Mobile</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.isMobileConfirm ? "Yes" : "No"}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Is Confirm Email</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.isEmailConfirm ? "Yes" : "No"}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Country</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">
                                    {userInfo.country}
                                </div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">ClientId</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">
                                    {userInfo.clientId}
                                </div>
                            </div>
                        </div>
                    }
                    <p class="sc-title hybeVE">Personl Details</p>
                    {userInfo != null && userInfo.profile != null &&
                        <div>
                            <div class="ant-row" style={{ gap: '24px' }} >
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Namtional</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.namtionalCountry}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Resident</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.residentCountry}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Resident Tax</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.residentTaxCountry}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Town</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.town}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">BirthDay</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.birthDate}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Postal Code</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.profile.postalCode}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Line Number</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">
                                        {userInfo.profile.lineNumber}
                                    </div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Pasport Number</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">
                                        {userInfo.profile.pasportNumber}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3>Address 1: {userInfo.profile.addressLine1}</h3>
                                <h3>Address 2: {userInfo.profile.addressLine2}</h3>
                            </div>
                        </div>
                    }
                    <p class="sc-title hybeVE">Source Of Funds</p>
                    {userInfo != null && userInfo.employee != null &&
                        <div class="ant-row" style={{ gap: '24px' }} >
                            <div class="sc-table-info">
                                <div class="sc-table-title">Status</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.status}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Industry</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.industry}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Company Name</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.companyName}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Description</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.description}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Annual Income</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.annualIncome}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">SavingsInvestment</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.employee.savingsInvestment}</div>
                            </div>
                        </div>
                    }
                    <p class="sc-title hybeVE">Trading Experience</p>
                    {userInfo != null && userInfo.tradeExprience != null &&
                        <div>
                            <div class="ant-row" style={{ gap: '24px' }} >
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Type</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.exprienceType}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Type Derivatives</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.experienceTypeDerivatives}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Type Comodities</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.experienceTypeComodities}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Trade Type</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.tradeType}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Level Fx</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.experiencedLevelFx}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Level Derivatives</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">{userInfo.tradeExprience.experiencedLevelDerivatives}</div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Level Comodities</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">
                                        {userInfo.tradeExprience.experiencedLevelComodities}
                                    </div>
                                </div>
                                <div class="sc-table-info">
                                    <div class="sc-table-title">Has Qualification</div>
                                    <div class="sc-line-midle"></div>
                                    <div class="sc-col-value">
                                        {userInfo.tradeExprience.hasQualification ? 'Yes' : 'No'}
                                    </div>
                                </div>
                            </div>
                            {userInfo.tradeExprience.description != null &&
                                <div>
                                    <h3>Description: {userInfo.tradeExprience.description}</h3>
                                </div>
                            }
                        </div>
                    }
                    <p class="sc-title hybeVE">Declaration</p>
                    {userInfo != null && userInfo.declaration != null &&
                        <div class="ant-row" style={{ gap: '24px' }} >
                            <div class="sc-table-info">
                                <div class="sc-table-title">Condition 1</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.declaration.condition ? "Yes" : "No"}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Condition 2</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.declaration.condition2 ? "Yes" : "No"}</div>
                            </div>
                            <div class="sc-table-info">
                                <div class="sc-table-title">Condition 3</div>
                                <div class="sc-line-midle"></div>
                                <div class="sc-col-value">{userInfo.declaration.condition3 ? "Yes" : "No"}</div>
                            </div>
                        </div>
                    }
                    <p class="sc-title hybeVE">Upload Document</p>

                    {userInfo != null && userInfo.files != null && userInfo.files.length != 0 &&
                        <Row>
                            <Col span={4}>
                                <List>
                                    <VirtualList
                                        data={userInfo.files}
                                        height={200}
                                        itemHeight={47}
                                        itemKey="id"
                                    >
                                        {(item) => (
                                            <List.Item key={item.id}>
                                                <List.Item.Meta
                                                    title={<a href={window.configurations.apiAddress + item.url} target="_blank" download rel="noreferrer" >{item.name}</a>}
                                                />
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            </Col>
                        </Row>
                    }
                </div>
            </Form>
        </div>
    );
};

export default VerifyUser;
