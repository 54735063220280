import { EditTwoTone, FormOutlined, SafetyOutlined } from "@ant-design/icons";
import {
    Form,
    Input,
    InputNumber,
    Table,
    Tooltip,
    Typography
} from "antd";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/tables.css";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Tables = () => {
    const [form] = Form.useForm();
    //const [data, setData] = useState(originData);
    const [data, setData] = useState([]);

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const deleteCookie = () => {
        let cookieValue = `admin-AccessToken = 1`;
        cookieValue = `${cookieValue}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        cookieValue = `${cookieValue}; domain=${window.location.hostname}; path=/;`;
        document.cookie = cookieValue;
        setTimeout(window.location.reload(), 1000);
    };

    const getUsers = () => {
        let params = {
            Page: 1,
            Limit: 100,
        };
        axios.get(window.configurations.apiAddress + 'admin/user',
            {
                headers:
                {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getCookie("admin-AccessToken"),
                },
                params
            }).then(({ data }) => {
                console.log(data);
                setData(data.items);
            }).catch((err) => {
                console.log(err);
                if (err?.response?.status === 401) {
                    deleteCookie();
                }
            })
    }

    useEffect(() => {
        getUsers();
    }, []);


    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: "",
            family: "",
            mobile: "",
            email: "",
            country: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const handleDelete = (key) => {
        const newData = data.filter((item) => item.key !== key);
        setData(newData);
    };

    //     handleTableChange = (pagination: any) => {
    //     this.setState({ loading: true });
    //     this.setState(
    //       {
    //         pageNumber: pagination.current - 1,
    //       },
    //       async () => await this.getCommodities()
    //     );
    //     this.setState({ loading: false });
    //     };

    //     handlePageNumber = (pageNumber: number, hasPage: boolean) => {
    //     this.setState(
    //       { pageNumber, selectedRows: [] },
    //       async () => !hasPage && (await this.getCommodities())
    //     );
    //   };

    const columns = [
        {
            key: "0",
            title: "Name",
            dataIndex: "name",
            width: "10%",
            editable: true,
        },
        {
            key: "1",
            title: "Family",
            dataIndex: "family",
            width: "10%",
            editable: true,
        },
        {
            key: "2",
            title: "Mobile",
            dataIndex: "mobile",
            width: "15%",
            editable: true,
        },
        {
            key: "3",
            title: "Email	",
            dataIndex: "email",
            width: "15%",
            editable: true,
        },
        {
            key: "4",
            title: "Country	",
            dataIndex: "country",
            width: "30%",
            editable: true,
        },
        {
            title: "Actions",
            width: "40%",
            dataIndex: "actions",
            render: (_, record) => {
                const editable = isEditing(record);
                return (
                    <>
                        <span className="admin-btn-action">
                            <Tooltip title="Edit">
                                <Typography.Link
                                    disabled={editingKey !== ""}
                                    onClick={() => edit(record)}
                                >
                                    <button
                                        style={{ borderColor: "#d5a847" }}
                                        className="ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary"
                                    >
                                        <Link to={"add"}>
                                            <EditTwoTone
                                                twoToneColor="#d5a847"
                                                style={{ fontSize: 14 }}
                                            />
                                        </Link>
                                    </button>
                                </Typography.Link>
                            </Tooltip>
                            <Tooltip title="KYC">
                                <Typography.Link>
                                    <button className="btn-kyc ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary">
                                        <Link to={"kyc/" + record.id}>
                                            <SafetyOutlined
                                                twoToneColor="#ff4d4f"
                                                style={{ fontSize: 14 }}
                                            />
                                        </Link>
                                    </button>
                                </Typography.Link>
                            </Tooltip>
                            <Tooltip title="KYC Edit">
                                <button className="btn-kyc ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary">
                                    <a href="https://user.elitecrescent.com/account" target={'_blank'} rel="noreferrer">
                                        <FormOutlined twoToneColor="#ff4d4f" />
                                    </a>
                                </button>
                            </Tooltip>
                        </span>
                    </>
                );
            },
        },
    ];

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    pageSize: 10,
                    showTitle: false,
                    showLessItems: true,
                }}
            />
        </Form>
    );
};

export default Tables;
