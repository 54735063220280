import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import React, { useState } from "react";
import logo from "../../images/Elitecrescent.svg";
import "./Login.css";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const onFinish = (values) => {
        setLoading(true);
        axios.post(window.configurations.apiAddress + 'account/Login',
            {
                userName: values.userName,
                password: values.password,
                isAdmin: true,
            },
            {
                headers:
                {
                    "Content-Type": "application/json",
                }
            }).then(({ data }) => {
                let cookieValue = `admin-AccessToken = ${data.token}`;
                const d = new Date();
                d.setTime(d.getTime() + (1*24*60*60*1000));
                cookieValue = `${cookieValue}; expires=${ d.toUTCString()}`;
                cookieValue = `${cookieValue}; domain=${window.location.hostname}; path=/;`;
                document.cookie = cookieValue;
                setLoading(false);
                message.success("Login success");
                window.location.reload();
            }).catch((err) => {
                message.error("Login failed");
                console.log(err);
                setLoading(false);
            });        
        };
    
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="login-wrapper">
            <div className="h-100 d-flex">
                <div className="container ant-col d-flex ">
                    <Card
                        bordered={true}
                        style={{
                            width: "403.08px",
                            height: "468.75px",
                            borderRadius: ".625rem",
                            boxShadow: "0 0 16px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div className="logo" >
                            <img src={logo} alt="Elite" />
                        </div>
                        <div
                            className="ant-row ant-row-center"
                            style={{
                                rowGap: 0,
                                marginBottom: "1.5rem",
                            }}
                        >
                            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-20 ant-col-lg-20">
                                <Form
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off">
                                    <Form.Item
                                        className="ant-form ant-form-vertical"
                                        label="Mobile"
                                        name="userName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input your mobile!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <UserOutlined className="site-form-item-icon text-primary" />
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input your password!",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockOutlined className="site-form-item-icon text-primary" />
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className=""
                                            loading = { loading}
                                        >
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Login;
