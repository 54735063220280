import { Layout } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ContentLayout from "./Components/Admin";
import HeaderLayout from "./Components/Header";
import Login from "./Components/Login/Login";
import Routers from "./Components/Routes/Routers";
import SiderLayout from "./Components/Sider";
import "./styles/App.css";

const App = () => {
    const [collapsed, setCollapsed] = useState(false);
    
    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const cookie = getCookie("admin-AccessToken");

    return cookie ? (
        <Layout
            style={{
                minHeight: "100vh",
            }}
        >
            <HeaderLayout collapsed={collapsed} setcollapsed={setCollapsed} />
            <Layout className="site-layout">
                <Router>
                    <SiderLayout collapsed={collapsed} />
                    <Routers>
                        <ContentLayout />
                    </Routers>
                </Router>
            </Layout>
        </Layout>
    ) : (
        <Login/>
    );
};

export default App;
