import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from "react";
import { Link } from "react-router-dom";
import '../styles/toolBar.css';
const { Search } = Input;

const Toolbar = (props) => {
    return (
        <div className="toolbar__Wrapper">
            <div className="d-flex">
                <Button 
                    icon={<PlusOutlined />}
                    >
                    <Link to ={props.linkTo ? props.linkTo :'/admin/users/add'}>{props.btnTxt}</Link>
                </Button>
                <Search
                    placeholder="Search..."
                    style={{
                        width: 200,
                        height:42
                    }}
                />
            </div>
        </div>
    );
};

export default Toolbar;
