import React from 'react';
import { Breadcrumb } from 'antd';
import '../styles/breadCrumb.css'

const BreadcrumbCmp = (props) => (
  <Breadcrumb>
    <Breadcrumb.Item>{props.item1}</Breadcrumb.Item>
    <Breadcrumb.Item>{props.item2}</Breadcrumb.Item>
  </Breadcrumb>
);

export default BreadcrumbCmp;