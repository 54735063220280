import React from "react";
import { Layout } from "antd";
import Tables from "./Tables";
import BreadcrumbCmp from "./‌Breadcrumb";
import Toolbar from "./Toolbar";
import "../styles/admin.css";
const { Content } = Layout;

const ContentLayout = () => {
    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 24,
                minHeight: "100vh",
            }}
        >
            <BreadcrumbCmp item1="Admin Panel" item2="Application Center" />
            <Toolbar btnTxt="New User" />
            <Tables />
        </Content>
    );
};

export default ContentLayout;
