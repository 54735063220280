import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Select, Upload, Button, Row, Col, Alert, message, List, Avatar } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import "../../styles/support.css";
import axios from 'axios';
import { Content } from 'antd/lib/layout/layout';

const { Option } = Select;
const acceptedFileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
];
const maxFileSize = 5 * 1024 * 1024; //5MB

const AnswerTicket = () => {

    const { id } = useParams();

    let useEffectRuned = false;
    let canRunNewRequest = true;
    const [ticketIsClosed, setticketIsClosed] = useState(true);
    const [messages, setMessages] = useState();
    const [ticket, setTicket] = useState();
    const [fileList, setFileList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();


    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const handleBeforeUpload = (file, fileList) => {
        const isFileTypeAccepted = acceptedFileTypes.includes(file.type);
        if (!isFileTypeAccepted) {
            const error = 'Invalid file type! Only JPEG, PNG, PDF, Word, Excel, and CSV files are allowed.';
            setErrorMessage(error);
            return Upload.LIST_IGNORE;
        }

        const isFileSizeValid = file.size <= maxFileSize;
        if (!isFileSizeValid) {
            const error = 'File size must be less than 5MB!';
            setErrorMessage(error);
            return Upload.LIST_IGNORE;
        }

        return true;
    };

    const handleCustomRequest = ({ file, onSuccess, onError }) => {
        setTimeout(() => {
            onSuccess();
        }, 2000);
    };

    const handleChange = (info) => {
        setFileList(info.fileList);
        setErrorMessage('');
    };

    const GetMessages = () => {
        axios.get(window.configurations.apiAddress + `Admin/Ticket/GetAdminTicketMessages/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie("admin-AccessToken")}`,
            },
        }).then(({ data }) => {

            var ticket = {
                ticketType: data.data.ticketType,
                status: data.data.status,
                ticketStatus: data.data.ticketStatus,
                ticketId: data.data.ticketId,
                title: data.data.title,
                creatorUserName: data.data.userName
            };

            setTicket(ticket);
            setticketIsClosed(ticket.ticketStatus == 5);

            var newMessages = data.data.messages.map((t) => ({
                messageId: t.messageId,
                sender: t.userName,
                createDate: t.createDate,
                message: t.message.split('\n'),
                attachments: t.attachments.map((a) => ({
                    url: a.url,
                    fileName: a.fileName
                }))
            }));

            setMessages(newMessages);


        }).catch((err) => {
            console.log(err);
        });
    };

    const handleCloseOrOpenTicket = (() => {
        if (canRunNewRequest) {
            canRunNewRequest = false;
            axios
                .post(
                    window.configurations.apiAddress + 'Ticket/UpdateTicketStatus',
                    {
                        ticketId: id,
                        Status: ticketIsClosed ? 1 : 5
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getCookie('x-AccessToken'),
                            'Content-Type': "application/json",
                        },
                    }
                )
                .then(({ data }) => {
                    setticketIsClosed(!ticketIsClosed);
                    canRunNewRequest = true;
                })
                .catch((error) => {
                    canRunNewRequest = true;
                    message.error('The operation was not successful');
                    console.log(error);
                });

        }
    });

    useEffect(() => {
        if (useEffectRuned === false) {
            useEffectRuned = true;
            GetMessages();
        }
    }, []);

    const onFinish = (values) => {
        if (canRunNewRequest) {
            canRunNewRequest = false;
            const formData = new FormData();

            formData.append('TicketId', id)
            formData.append('Message', values.message);
            formData.append('CalledByAdmin', true);
            if (fileList.length > 0) {
                fileList.forEach((file) => {
                    formData.append('Files', file.originFileObj);
                });
            }

            axios
                .post(
                    window.configurations.apiAddress + 'Admin/Ticket/AddMessageToTicket',
                    formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getCookie('admin-AccessToken'),
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                .then(({ data }) => {
                    canRunNewRequest = true;
                    message.success('The ticket has been sent successfully');

                    navigate('/admin/support');
                })
                .catch((error) => {
                    canRunNewRequest = true;
                    message.error('The ticket was not sent successfully');
                    console.log(error);
                });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 24,
                minHeight: "100vh",
            }}
        >
            <div className='innerSection'>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='support-head-title'><h3>Answer Ticket</h3></div>
                    </Col>
                </Row>
            </div>
            <div className='innerSection'>
                <Form
                    className='support-form'
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    {ticket &&
                        <Row gutter={16}>
                            <Col span={7}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 8 }}>Subject</label>
                                    <h4>{ticket.title}</h4>
                                </div>
                            </Col>

                            <Col span={5}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 8 }}>Ticket type</label>
                                    <h4>{ticket.ticketType}</h4>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 8 }}>Status</label>
                                    <h4>{ticket.status}</h4>
                                </div>
                            </Col>
                            <Col span={2}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button style={{
                                        backgroundColor: "#d7a847",
                                        border: "none",
                                        borderRadius: "12px",
                                        width: '100%',
                                    }} onClick={handleCloseOrOpenTicket}>{ticketIsClosed && 'Open'}{!ticketIsClosed && 'Close'}</Button>
                                </div>
                            </Col>
                        </Row>}
                    {!ticketIsClosed &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 8 }}>Message</label>
                                    <Form.Item name="message" rules={[{ required: true, message: 'Please enter the message' }]} wrapperCol={{
                                        offset: 0,
                                        span: 20,
                                    }}>
                                        <Input.TextArea placeholder="Write what you want to tell us" autoSize={{ minRows: 10, maxRows: 10 }} style={{ width: '100%' }} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    }
                    {!ticketIsClosed &&
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Upload"
                                    name="upload"
                                >
                                    <Upload
                                        beforeUpload={handleBeforeUpload}
                                        customRequest={handleCustomRequest}
                                        fileList={fileList}
                                        onChange={handleChange}
                                    >
                                        <Button icon={<UploadOutlined />} style={{
                                            backgroundColor: "#d7a847",
                                            border: "none",
                                            borderRadius: "12px",
                                            width: '100%',
                                        }}>
                                            Click to Upload
                                        </Button>
                                    </Upload>
                                    {errorMessage && (
                                        <Alert
                                            message={errorMessage}
                                            type="error"
                                            closable
                                            onClose={() => setErrorMessage('')}
                                            style={{ marginTop: '10px' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 10,
                                        span: 5,
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            backgroundColor: "#d7a847",
                                            border: "none",
                                            borderRadius: "12px",
                                            width: '100%'
                                        }}
                                    >
                                        Send
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    }

                </Form>
            </div>
            {messages &&
                <div>
                    <List
                        itemLayout="horizontal"
                        dataSource={messages}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar>{item.sender[0].toUpperCase()}</Avatar>}
                                    title={item.sender}
                                    description={
                                        <div>
                                            {item.message.map((mes, index) => (

                                                <p key={index} style={{ marginBottom: '1px' }}>{mes}</p>

                                            ))}
                                            {/* {item.message}     */}

                                            {item.attachments.map((attachment, index) => (
                                                <div key={index}>
                                                    <a href={window.configurations.apiAddress + attachment.url} download={attachment.fileName} target="_blank">
                                                        {attachment.fileName}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </div>}
        </Content>
    );
};

export default AnswerTicket;