import React, { useState } from "react";
import {
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Table,
    Typography,
    Tooltip,
} from "antd";
import { EditTwoTone, FullscreenOutlined } from "@ant-design/icons";

import "../../styles/htmlPart.css";
import Toolbar from "../Toolbar";
import BreadcrumbCmp from "../‌Breadcrumb";
import { Link } from "react-router-dom";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const termConditionData = [
    { name: "firstcondition" },
    { name: "second condition" },
    { name: "third condittion" },
    { name: "zxzxzxzx" },
];
const TermCondition = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState(termConditionData);
    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            name: "",
            createDate: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            key: "0",
            title: "Name",
            dataIndex: "name",
            width: "52%",
            editable: true,
        },

        {
            title: "Actions",
            width: "30%",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <>
                        <span>
                            <Tooltip title="Edit">
                                <Typography.Link>
                                    <button
                                        style={{ borderColor: "#d5a847" }}
                                        className="ant-btn ant-btn-sm ant-btn-icon-only ant-btn-dangerous mr-2  d-flex justify-content-center align-items-center btn-outline-primary"
                                    >
                                         <Link to={"add/1"}>
                                            <EditTwoTone
                                                twoToneColor="#d5a847"
                                                style={{ fontSize: 14 }}
                                            />
                                        </Link>
                                    </button>
                                </Typography.Link>
                            </Tooltip>
                        </span>
                    </>
                );
            },
        },
    ];

    return (
        <div className="html-part">
            <BreadcrumbCmp
                item1="Admin Panel"
                item2="Term And Condition List"
            />
            <Toolbar btnTxt="Add New" linkTo = 'add'/>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    // pageSize:  ,
                    defaultPageSize: 5,
                    showTitle: false,
                    showLessItems: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
            />
        </div>
    );
};

export default TermCondition;
